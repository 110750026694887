import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog({
  open,
  onClose,
  title,
  content,
  actions = null,
  confirmationButtonText = "Confirm",
  cancellationButtonText = "Cancel",
  onConfirm = null,
  onCancel = null,
}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>{content}</DialogContent>
        {actions ? (
          <DialogActions>{actions}</DialogActions>
        ) : (
          <DialogActions>
            {onCancel && cancellationButtonText && (
              <Button autoFocus onClick={onCancel}>
                {cancellationButtonText}
              </Button>
            )}
            {onConfirm && confirmationButtonText && (
              <Button onClick={onConfirm}>{confirmationButtonText}</Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
}
