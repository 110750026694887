import React, { useState, useEffect, useContext, useRef } from "react";
import { get, set } from "lodash";
import { useConversationsApi } from "../../api/conversationsApi";
import { parse, Allow } from "partial-json";
import CloseIcon from "@mui/icons-material/Close";
import EditNoteIcon from "@mui/icons-material/EditNote";

import {
  TextField,
  InputAdornment,
  Button,
  Container,
  Box,
  CircularProgress,
  Typography,
  Tooltip,
  Backdrop,
  Stack,
  Badge,
  MenuItem,
  ListItemIcon,
  Menu,
  ClickAwayListener,
  IconButton,
} from "@mui/material";

import AttachFileTwoToneIcon from "@mui/icons-material/AttachFileTwoTone";

import { BsFillSendFill } from "react-icons/bs";
import { useDropzone } from "react-dropzone";

import { ConversationContext } from "./providers/ConversationProvider.js";
import { useTheme } from "@mui/material/styles";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { useDocumentsApi } from "../../api/documentsApi";
import FileDisplay from "./components/FileDisplay.jsx";
import { MessageFile } from "./classes/MessageFile.js";
import FileSelectionDialog from "./components/FileSelectionDialog.jsx";
import MessageList from "./components/MessageList.jsx";

import { useWebsockets } from "./hooks/useWebsockets";
import DrawerComponent from "./components/DrawerComponent";
import AgentDrawer from "./components/AgentsDrawer.jsx";
import ActiveAgents from "./components/ActiveAgents.jsx";
import ModelSelectionMenu from "./components/ModelSelectionMenu.jsx";
import { useParams } from "react-router-dom";

const drawerWidth = 300;

// Conversations component takes "mode" as a prop, which is the theme mode
function Conversations(props) {
  const { id } = useParams();

  const theme = useTheme();
  const { query } = useConversationsApi();
  const {
    fetchUploadUrl,
    uploadDocument,
    getAllUploadedFilesForConversationDisplay,
    getDownloadUrl,
  } = useDocumentsApi();
  const textareaRef = React.useRef(null);

  const [activeAgentsOpen, setActiveAgentsOpen] = React.useState(false);

  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isThinking, setIsThinking] = useState(false);

  const {
    selectedConversationRef,
    setSelectedConversation,
    refreshMessagesTrigger,
    setStatus,
    setStatusOpen,
    setMessageHistory,
    currentMessage,
    setCurrentMessage,
    conversationAgents,
    isLoading,
    waitingForConversation,
    setWaitingForConversation,
    // clearConversationState,
    changeUrl,
    requestConversationListRefresh,
    localFileList,
    setLocalFileList,
    localFileHandles,
    setLocalFileHandles,
    setIsStreaming,
    isStreaming,
    fetchMessages,
  } = useContext(ConversationContext);

  // ref for the local file handles so we can access them in the handleLocalFileData function
  const localFileHandlesRef = useRef(localFileHandles);

  const { webSocketControl } = useWebsockets(
    // Use a refs to avoid stale closures
    (data) => {
      // console.log(
      //   "Message handler called, current ref:",
      //   selectedConversationRef.current
      // );
      handleMessageIdData(data);
    },
    (data) => {
      // console.log(
      //   "Status handler called, current ref:",
      //   selectedConversationRef.current
      // );
      handleStatusData(data);
    },
    (data) => {
      // console.log(
      //   "Local file handler called, current ref:",
      //   selectedConversationRef.current
      // );
      handleLocalFileData(data);
    }
  );

  // Create a ref to the websocket so we can access it in the handleLocalFileData function
  const webSocketControlRef = useRef(webSocketControl);

  const hasFileSystemAccess = Boolean(window?.showOpenFilePicker);

  useEffect(() => {
    // console.log("Selected conversation (id) changed:", id);
    if (id) {
      if (id !== "new" && id !== selectedConversationRef.current) {
        // console.log("Id: ", id);
        // console.log(
        //   "Selected conversation ref: ",
        //   selectedConversationRef.current
        // );
        setSelectedConversation(id);
      }
    } else {
      setSelectedConversation("new");
    }
    // clearConversationState();
  }, [id]);

  // useEffect for keeping the webSocketControlRef up to date with the webSocketControl
  useEffect(() => {
    webSocketControlRef.current = webSocketControl;
  }, [webSocketControl]);

  // effect for keeping the fileHandlesRef up to date with the localFileHandles
  useEffect(() => {
    localFileHandlesRef.current = localFileHandles;
  }, [localFileHandles]);

  async function isTextFile(file) {
    try {
      // Read the first few KB of the file
      const sample = file.slice(0, 4096);
      const text = await sample.text();

      // Check 1: No null bytes
      if (text.includes("\0")) {
        return false;
      }

      // Check 2: Valid UTF-8
      const encoder = new TextEncoder();
      const decoder = new TextDecoder("utf-8", { fatal: true });
      const encoded = encoder.encode(text);
      try {
        decoder.decode(encoded);
      } catch (e) {
        return false;
      }

      // Check 3: Reasonable ratio of printable to non-printable characters
      const printableChars = text.replace(/[\x00-\x1F\x7F-\x9F]/g, "").length;
      const ratio = printableChars / text.length;
      if (ratio < 0.8) {
        // At least 80% should be printable characters
        return false;
      }

      // Check 4: No long sequences of null bytes or repeated characters
      const repeatedCharRegex = /(.)\1{100,}/;
      if (repeatedCharRegex.test(text)) {
        return false;
      }

      return true;
    } catch (e) {
      return false;
    }
  }

  async function handleLocalFileInput() {
    try {
      const handles = await window.showOpenFilePicker({
        multiple: true,
        types: [
          {
            description: "All Files",
            accept: {
              "*/*": [],
            },
          },
        ],
      });

      // Check file sizes
      const MAX_SIZE = 100 * 1024; // 100KB in bytes
      const newFileHandles = {};
      const fileNames = [];

      for (const handle of handles) {
        // If the name is already in the local file list, skip it
        if (localFileList.includes(handle.name)) {
          alert(`File "${handle.name}" is already attached`);
          continue;
        }

        const file = await handle.getFile();

        if (file.size > MAX_SIZE) {
          alert(`File "${file.name}" exceeds 100KB limit`);
          continue; // Skip this file
        }

        // Check if it's a text file
        if (!(await isTextFile(file))) {
          alert(`File "${file.name}" is not a text file`);
          continue;
        }

        newFileHandles[handle.name] = handle;
        fileNames.push(handle.name);
      }

      // Add the file names to the local file list and handles
      setLocalFileList((prevList) => {
        return [...prevList, ...fileNames];
      });

      setLocalFileHandles((prevHandles) => {
        return { ...prevHandles, ...newFileHandles };
      });

      // Close the local file list if it's open
      handleCloseLocalFileList();
      // Close the attachment menu
      handleAttachmentMenuClose();
    } catch (err) {
      if (err.name !== "AbortError") {
        console.error("Error selecting files:", err);
      }
    }
  }

  // Write to file
  async function writeLocalFile(fileName, content) {
    try {
      // console.log("Writing to file:", fileName);
      const handle = localFileHandlesRef.current[fileName];

      if (!handle) {
        console.error(`No handle found for file: ${fileName}`);
        throw new Error(`No handle found for file: ${fileName}`);
      }

      // Request write permission for existing file
      // console.log("Requesting write permission for file:", fileName);
      const writable = await handle.createWritable();

      // Write the content
      // console.log("Writing to file:", fileName);
      await writable.write(content);

      // Close the file
      await writable.close();

      // console.log("File written:", fileName);
    } catch (err) {
      console.error(`Error writing to file ${fileName}:`, err);
      throw err;
    }
  }

  // read local file
  async function readLocalFile(fileName) {
    try {
      // console.log("Reading file:", fileName);
      const handle = localFileHandlesRef.current[fileName];

      if (!handle) {
        console.error(`No handle found for file: ${fileName}`);
        throw new Error(`No handle found for file: ${fileName}`);
      }

      // Request read permission
      // console.log("Requesting read permission for file:", fileName);
      const file = await handle.getFile();
      const reader = new FileReader();
      const content = await new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsText(file);
      });

      return content;
    } catch (err) {
      console.error(`Error reading file ${fileName}:`, err);
      throw err;
    }
  }

  function removeLocalFileFromList(fileName) {
    setLocalFileList((prevList) => {
      return prevList.filter((file) => file !== fileName);
    });

    setLocalFileHandles((prevHandles) => {
      const newHandles = { ...prevHandles };
      delete newHandles[fileName];
      return newHandles;
    });
  }

  // useEffect to handle when localFileList changes
  useEffect(() => {
    if (localFileList.length === 0) {
      handleCloseLocalFileList();
    }
  }, [localFileList]);

  const [localFileListOpen, setLocalFileListOpen] = useState(false);

  function handleCloseLocalFileList() {
    setLocalFileListOpen(false);
  }

  function handleOpenLocalFileList() {
    setLocalFileListOpen(true);
  }

  // useEffect to handle when refreshMessagesTrigger changes
  useEffect(() => {
    if (
      selectedConversationRef.current &&
      selectedConversationRef.current !== "new"
    ) {
      handleTextareaFocus();
    }
  }, [refreshMessagesTrigger]);

  const [accumulatedBytes, setAccumulatedBytes] = useState([]);
  const [accumulatedData, setAccumulatedData] = useState({
    thoughts: "",
    status: {},
    answer: "",
    disclaimer: "",
  });

  useEffect(() => {
    if (accumulatedBytes && accumulatedBytes.length > 0) {
      let accumulatedText = new TextDecoder("utf-8").decode(accumulatedBytes);

      let data = parse(accumulatedText, Allow.ALL);

      if (data["conversation_wrapper"]) {
        if (
          data["conversation_wrapper"]["conversation_id"] &&
          (!selectedConversationRef.current ||
            selectedConversationRef.current === "new")
        ) {
          const conversationId =
            data["conversation_wrapper"]["conversation_id"];
          onConversationIdReceived(conversationId);
        }

        if (data["conversation_wrapper"]["answer_stage"]) {
          handleAnswerStage(data);
        }

        if (data["conversation_wrapper"]["error"]) {
          onErrorReceived(data["conversation_wrapper"]["error"]);
        }
      }
    }
  }, [accumulatedBytes]);

  function handleAnswerStage(data) {
    if (data["conversation_wrapper"]["answer_stage"]["answer"]) {
      if (
        accumulatedData.answer !==
        data["conversation_wrapper"]["answer_stage"]["answer"]
      ) {
        // Update the answer in the accumulatedData
        setAccumulatedData({
          ...accumulatedData,
          answer: data["conversation_wrapper"]["answer_stage"]["answer"],
        });

        onAnswerReceived(
          data["conversation_wrapper"]["answer_stage"]["answer"]
        );
      }
    }
  }

  const handleTextareaFocus = () => {
    const current = textareaRef.current;
    current?.focus();
  };

  useEffect(() => {
    if (waitingForConversation) {
      return;
    }

    handleTextareaFocus();
  }, [selectedConversationRef.current]);

  function handleStatusData(data) {
    // This is a STATUS update from the websocket
    // Update the status and description
    // Only update if the conversation_id matches the selected conversation
    if (data.conversation_id === selectedConversationRef.current) {
      // use setStatus to append the new status data to the existing status data
      SetStatusData(data);
    } else {
      console.warn(
        "Ignoring status data because conversation_id does not match selected conversation" +
          data.conversation_id +
          " !== " +
          selectedConversationRef.current
      );
    }
  }

  function handleLocalFileData(data) {
    // This is a LOCAL FILE request from the websocket
    // It could be a read, or it could be a write.
    // Look at the localFileHandles to see if the filename is there
    // If it is not, send a message back to the websocket with an error
    // operation_name is either "read" or "write"
    // data.transaction_id is the transaction ID to repeat back when responding to the websocket
    // data.local_file_name is the name of the file to read or write
    // if it's a write, data.data is the data to write to the file
    // if it's a read, read the local_file_name and send the data back to the websocket
    // When we send the data back, we need to include the transaction_id, and set the data.message field
    // If it's a read, the data.data field should be the contents of the file

    // console.log("Local file data:", data);

    if (data.conversation_id === selectedConversationRef.current) {
      if (data.operation_name === "read") {
        // console.log("Local file read request for", data.local_file_name);
        // // Check if the file exists
        if (localFileHandlesRef.current[data.local_file_name]) {
          // Read the file
          readLocalFile(data.local_file_name).then((content) => {
            // Send the data back to the websocket
            const response = {
              conversation_id: data.conversation_id,
              operation_name: data.operation_name,
              local_file_name: data.local_file_name,
              transaction_id: data.transaction_id,
              message: "File data for " + data.local_file_name,
              data: content,
            };

            webSocketControlRef.current.send(JSON.stringify(response));
          });
        } else {
          // Send an error message back to the websocket
          const response = {
            conversation_id: data.conversation_id,
            operation_name: data.operation_name,
            local_file_name: data.local_file_name,
            transaction_id: data.transaction_id,
            message: "File data not found for " + data.local_file_name,
            data: null,
          };

          webSocketControlRef.current.send(JSON.stringify(response));
        }
      } else if (data.operation_name === "write") {
        // console.log("Local file write request for", data.local_file_name);
        // Check if the file exists
        if (localFileHandlesRef.current[data.local_file_name]) {
          //   // Write the file
          // console.log("File exists: ", data.local_file_name);
          writeLocalFile(data.local_file_name, data.data).then(() => {
            // Send the data back to the websocket
            const response = {
              conversation_id: data.conversation_id,
              operation_name: data.operation_name,
              local_file_name: data.local_file_name,
              transaction_id: data.transaction_id,
              message: "Local file written",
              data: null,
            };

            try {
              webSocketControlRef.current.send(JSON.stringify(response));
            } catch (error) {
              console.error("Error sending local file write response:", error);
            }
          });
        } else {
          // console.log("File does not exist: ", data.local_file_name);
          // Write all of the local files to the console for debugging:
          // console.log("Local files: ", localFileHandlesRef.current);
          // Send an error message back to the websocket
          const response = {
            conversation_id: data.conversation_id,
            operation_name: data.operation_name,
            local_file_name: data.local_file_name,
            transaction_id: data.transaction_id,
            message:
              "Local file not found.  Tell the user to verify that it's attached.",
            data: null,
          };

          try {
            webSocketControlRef.current.send(JSON.stringify(response));
          } catch (error) {
            console.error("Error sending local file write response:", error);
          }
        }
      }
    } else {
      console.warn(
        "Ignoring local file data because conversation_id does not match selected conversation.  Incoming: " +
          data.conversation_id +
          " !== Selected: " +
          selectedConversationRef.current
      );
    }
  }

  function handleMessageIdData(data) {
    // New: In the currentMessage, update the message ID with the real ID
    // Then move the message to the messageHistory, also loop through all
    // of the messages in the history (should be the latest one) in reverse
    // order to find the message with the temporary ID and update it with the real ID
    // Only update if the conversation_id matches the selected conversation
    if (data.conversation_id === selectedConversationRef.current) {
      setCurrentMessage((prevMessage) => {
        if (prevMessage.temporary_id === data.temporary_id) {
          return {
            ...prevMessage,
            id: data.message_id,
          };
        } else {
          return prevMessage;
        }
      });

      setMessageHistory((prevMessages) => {
        const newMessages = [...prevMessages];
        for (let i = newMessages.length - 1; i >= 0; i--) {
          const message = newMessages[i];
          if (message.temporary_id === data.temporary_id) {
            // Update the message with the real ID
            newMessages[i].id = data.message_id;
            return newMessages;
          }
        }
        return newMessages;
      });
    }
  }

  const SetStatusData = (data) => {
    // incoming data looks like this:
    // { conversation_id: "09029f47-27c8-48e4-b3a2-a7207aee8448", operation_id: "09025f26-26c8-24e4-a3a1-a5741aee8448", agent_name: "Zippy", status: "in_progress", description: "Processing query..." }

    if (data === undefined) {
      console.warn("Ignoring undefined status data");
      return;
    }

    // Setting the status is additive, so we need to append the new status data to the existing status data
    // The status data looks like this: { Zippy: [ { status: "in_progress", description: "Processing query..." } ] }
    // For each new status we get in, we want to append it to the existing agent's status data
    // Like so: { Zippy: [ { status: "in_progress", description: "Processing query..." }, { status: "finished", description: "Query complete" } ] }

    setStatus((prevStatus) => {
      // If the agent doesn't exist in the status data, add it
      if (!prevStatus[data.agent_name]) {
        set(prevStatus, data.agent_name, []);
      }

      // Check to see if the operation ID already exists in the status data
      const operationIndex = prevStatus[data.agent_name].findIndex(
        (statusData) => statusData.operationId === data.operation_id
      );

      if (operationIndex !== -1) {
        // If this operation ID already exists, update the status data
        prevStatus[data.agent_name][operationIndex] = {
          status: data.status,
          description: data.description,
          operationId: data.operation_id,
          operationName: data.operation_name,
        };
      } else {
        // If this operation ID does not exist, add the new status data to the existing status data
        prevStatus[data.agent_name].push({
          status: data.status,
          description: data.description,
          operationId: data.operation_id,
          operationName: data.operation_name,
        });
      }

      return { ...prevStatus };
    });
  };

  function onConversationIdReceived(conversationId) {
    // console.log("Receiving new conversation ID:", conversationId);
    // console.log("Current ref before update:", selectedConversationRef.current);
    setSelectedConversation(conversationId);
    // console.log("Current ref after update:", selectedConversationRef.current);
  }

  const streamStarted = () => {
    setIsStreaming(true);
    setStatusOpen(true);
  };

  const onAnswerReceived = (newAnswer) => {
    setIsThinking(false);
    setCurrentMessage((prevMessage) => {
      return {
        ...prevMessage,
        content: newAnswer,
      };
    });
  };

  const onErrorReceived = (conversationId, error) => {
    console.error("Error received for conversation ID:", conversationId);
    setIsThinking(false);
    setCurrentMessage(null);
    setSelectedConversation(conversationId);
    // reload the messages
    fetchMessages(conversationId).then(() => {
      // console.log("Messages reloaded after error");
    });
  };

  function streamCompleted(conversationId) {
    // console.log("Stream completed for conversation ID:", conversationId);
    setIsSendingMessage(false);
    setIsStreaming(false);
    setIsThinking(false);
    setWaitingForConversation(false);
    setStatusOpen(false);

    // Change the URL to the conversation ID

    if (conversationId !== "new") {
      changeUrl(conversationId);
    }

    // Refresh the conversation list after like 2 seconds
    setTimeout(() => {
      requestConversationListRefresh();
    }, 2000);
  }

  const onSubmit = async () => {
    const message = document.getElementById("message-textfield").value;

    await onSubmitMessage(message);
  };

  const onSubmitMessage = async (message) => {
    // Don't do anything if the field is empty
    if (!message) {
      return;
    }

    // Clear the status
    setStatus({});

    if (
      !selectedConversationRef.current ||
      selectedConversationRef.current === "new"
    ) {
      setWaitingForConversation(true);
      // Clear messages for the new conversation
      setMessageHistory([]);
    }

    setIsSendingMessage(true);

    document.getElementById("message-textfield").value = "";

    let associatedFileDetails = {};
    let messageFiles = {};
    // Upload the files that haven't been uploaded (first filter the files that haven't been uploaded, then upload them)
    const filesToUpload = Object.values(droppedFiles).filter(
      (file) => !file.uploaded
    );

    // For all of the files that have already been uploaded, associate them with the message
    for (const file of Object.values(droppedFiles).filter(
      (file) => file.uploaded
    )) {
      associatedFileDetails[file.file_name] = file.key;
    }

    // Add all of the dropped files to the messageFiles object
    for (const file of Object.values(droppedFiles)) {
      messageFiles[file.file_name] = file;
    }

    const uploadPromises = Object.values(filesToUpload).map(async (file) => {
      // If the file is already uploaded, skip the upload, but add it to the message
      // Get the upload URL
      const details = await fetchUploadUrl(file);
      associatedFileDetails[details.file_name] = details.key;

      // Also add the uploaded file key to the messageFiles (so the user can delete it before refreshing the page)
      messageFiles[details.file_name].key = details.key;

      // Upload the file
      return uploadDocument(
        file.fileObject,
        details,
        updateDroppedFileProgress,
        uploadComplete
      );
    });

    // Clear the dropped files display (should now be displayed in the message files)
    setDroppedFiles({});

    // Create some temporary IDs (strings) for the user and AI messages
    const temporaryUserMessageId = "user-" + Date.now();
    const temporaryAiMessageId = "ai-" + Date.now();

    // Move the previous currentMessage to the messageHistory (if there is one)
    if (currentMessage) {
      setMessageHistory((prevMessages) => [
        ...prevMessages,
        {
          temporary_id: currentMessage.temporary_id,
          id: currentMessage.id,
          role: currentMessage.role,
          content: currentMessage.content,
          associated_files: currentMessage.associated_files,
          reasoningSteps: currentMessage.reasoningSteps,
          thought: currentMessage.thought,
          disclaimer: currentMessage.disclaimer,
        },
      ]);
    }

    // Add the user's message to the conversation
    setMessageHistory((prevMessages) => [
      ...prevMessages,
      {
        temporary_id: temporaryUserMessageId,
        id: null,
        role: "user",
        content: message,
        associated_files: messageFiles,
      },
    ]);

    // Wait for all uploads to complete
    await Promise.all(uploadPromises);

    // Create the initial AI message
    setCurrentMessage({
      temporary_id: temporaryAiMessageId,
      id: null,
      role: "ai",
      content: "",
    });

    query(
      selectedConversationRef.current,
      message,
      conversationAgents,
      temporaryUserMessageId,
      temporaryAiMessageId,
      associatedFileDetails,
      localFileHandles,
      setAccumulatedBytes,
      streamCompleted,
      streamStarted,
      onErrorReceived
    );
  };

  const [isDragActive, setIsDragActive] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState({});

  function uploadComplete(fileName) {
    removeDroppedFile(fileName);
  }

  async function associateExistingFileWithOutgoingMessage(messageFile) {
    // Get the download URL for the file
    const downloadUrl = await getDownloadUrl(messageFile.key);

    messageFile.url = downloadUrl;

    setDroppedFiles((prevFiles) => {
      return {
        ...prevFiles,
        [messageFile.file_name]: messageFile,
      };
    });
  }

  const onPaste = (event) => {
    const items = event.clipboardData.items;
    const files = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === "file") {
        const file = items[i].getAsFile();
        if (file) {
          files.push(file);
        }
      }
    }
    if (files.length > 0) {
      // Call addDroppedFile for each file
      files.forEach((file) => {
        addDroppedFile(file);
      });
    }
  };

  async function addDroppedFile(file) {
    let imgSrc = null;
    if (file.type.startsWith("image/")) {
      imgSrc = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    }

    let fileObject = new MessageFile(
      file.name,
      file.type,
      imgSrc,
      null,
      null,
      file
    );

    setDroppedFiles((prevFiles) => {
      return {
        ...prevFiles,
        [fileObject.file_name]: fileObject,
      };
    });
  }

  function removeDroppedFile(file) {
    setDroppedFiles((prevFiles) => {
      const newFiles = { ...prevFiles };
      delete newFiles[file.file_name];
      return newFiles;
    });
  }

  function updateDroppedFileProgress(fileName, rawProgress) {
    // Make the progress a whole number
    let progress = Math.round(rawProgress);

    if (progress === null || progress === undefined) {
      progress = 100;
    }

    if (progress >= 100) {
      uploadComplete(fileName);
    }

    setMessageHistory((prevMessages) => {
      const newMessages = [...prevMessages];
      const lastMessageIndex = newMessages.length - 1;
      const lastMessage = { ...newMessages[lastMessageIndex] };
      const newAssociatedFiles = { ...lastMessage.associated_files };

      if (newAssociatedFiles && newAssociatedFiles[fileName]) {
        newAssociatedFiles[fileName].progress = progress;
      }

      lastMessage.associated_files = newAssociatedFiles;
      newMessages[lastMessageIndex] = lastMessage;

      return newMessages;
    });
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        addDroppedFile(file);
      });

      setIsDragActive(false);
    },
    onDragEnter: () => setIsDragActive(true),
    onDragOver: () => setIsDragActive(true),
    onDragLeave: () => setIsDragActive(false),
  });

  // const [attachmentMenuAnchorEl, setAttachmentMenuAnchorEl] = useState(null);
  const [attachmentMenuOpen, setAttachmentMenuOpen] = useState(false);

  const handleAttachmentMenuClick = (event) => {
    event.stopPropagation();
    // setAttachmentMenuAnchorEl(event.currentTarget);
    setAttachmentMenuOpen(true);
  };

  const handleAttachmentMenuClose = () => {
    // event.stopPropagation();
    // setAttachmentMenuAnchorEl(null);
    setAttachmentMenuOpen(false);
  };

  const [fileSelectionOpen, setFileSelectionOpen] = useState(false);
  const [availableFiles, setAvailableFiles] = useState([]);

  const handleSelectFileFromExisting = async () => {
    // Get the files for this user
    const availableFiles = await getAllUploadedFilesForConversationDisplay();

    // turn the files into a list of MessageFile objects
    const availableFilesList = availableFiles.map((file) => {
      return new MessageFile(
        file.file_name,
        file.file_type,
        null,
        file.ui_key,
        file.file_description,
        null,
        0,
        true
      );
    });

    setAvailableFiles(availableFilesList);

    setFileSelectionOpen(true);
  };

  const onFileSelectionClosed = async (selectedFiles) => {
    setFileSelectionOpen(false);

    if (selectedFiles && selectedFiles.length > 0) {
      // Add the selected files to the dropped files
      for (const file of selectedFiles) {
        await associateExistingFileWithOutgoingMessage(file);
      }
    }
  };

  return (
    <Container
      id="main-conversation-container"
      disableGutters
      maxWidth={false}
      sx={{
        // overflow: "auto",
        display: "flex",
        flexDirection: "column",
      }}
      {...getRootProps({ onClick: (event) => event.stopPropagation() })} // Dropzone props
      // {...rightSwipeHandlers} // Swipeable props
    >
      <FileSelectionDialog
        onClose={onFileSelectionClosed}
        open={fileSelectionOpen}
        files={availableFiles}
      />
      <Box
        id="file-upload-box"
        sx={{
          position: "fixed",
          width: "100%",
          height: "100%",
          display: isDragActive ? "flex" : "none",
          bgcolor: "rgba(233, 233, 233, 0.75)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      />
      <input
        type="hidden"
        name="fileUploadInput"
        {...getInputProps({ onClick: (event) => event.stopPropagation() })}
      />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <DrawerComponent drawerWidth={drawerWidth} />
        <AgentDrawer
          activeAgentsOpen={activeAgentsOpen}
          setActiveAgentsOpen={setActiveAgentsOpen}
        />

        <Box
          id="message-box"
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", sm: "calc(100vw - " + drawerWidth + "px)" },
          }}
        >
          <Box
            sx={{
              maxHeight: "30px",
              m: 1,
              mr: "25px",
              zIndex: 100,
              position: "fixed",
              right: 0,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Stack direction="column">
              <ActiveAgents onClick={() => setActiveAgentsOpen(true)} />
              <ModelSelectionMenu
                conversationId={selectedConversationRef.current}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  color: theme.palette.primary.contrastText,
                }}
              />
            </Stack>
          </Box>

          <Box
            id="message-container"
            direction={"column"}
            sx={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
              height: "100dvh",
              maxHeight: { xs: "calc(100dvh - 30px)", sm: "100dvh" },
            }}
          >
            <Backdrop
              id="loading-backdrop"
              open={isLoading}
              sx={{
                maxWidth: {
                  xs: "100%",
                  sm: "calc(100vw - " + drawerWidth + "px)",
                },
                left: { sm: drawerWidth },
                top: { sm: "64px", xs: "56px" },
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer,
              }}
            >
              <CircularProgress sx={{ color: theme.palette.primary.main }} />
            </Backdrop>

            <MessageList
              conversationId={selectedConversationRef.current}
              isThinking={isThinking}
              isSendingMessage={isSendingMessage}
              theme={theme}
              drawerWidth={drawerWidth}
              onSubmitMessage={onSubmitMessage}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: {
                  xs: "100%",
                  sm: "calc(100vw - " + drawerWidth + "px)",
                },
                pb: 10,
                pl: 5,
                pr: 5,
              }}
              id="user-query-box"
            >
              <FileDisplay
                removeFile={removeDroppedFile}
                files={droppedFiles}
                isMessage={false}
                canRemove={true}
              />

              <form noValidate autoComplete="off">
                <TextField
                  id={"message-textfield"}
                  size="small"
                  label={
                    !isStreaming
                      ? "Enter your message here..."
                      : "Please wait for Zippy to finish..."
                  }
                  inputRef={textareaRef}
                  multiline
                  maxRows={15}
                  sx={{
                    color: !isStreaming
                      ? theme.palette.secondary.main
                      : theme.palette.primary.dark,
                  }}
                  fullWidth={true}
                  onPaste={onPaste}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      if (!isStreaming) {
                        onSubmit();
                      }
                    }
                  }}
                  InputProps={{
                    sx: { alignItems: "end" },
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{
                          alignItems: "flex-start",
                          alignSelf: "flex-start",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Tooltip
                          title={
                            localFileList.length > 0
                              ? "Local files are attached to this conversation"
                              : "Attach a file (or files) to your message"
                          }
                        >
                          <Badge
                            // variant="dot"
                            badgeContent={localFileList.length}
                            color="primary"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <AttachFileTwoToneIcon
                              sx={{
                                marginRight: "4px",
                                height: "30px",
                                cursor: "pointer",
                                color: theme.palette.primary.contrastText,
                              }}
                              onClick={handleAttachmentMenuClick}
                            />
                          </Badge>
                        </Tooltip>

                        <ClickAwayListener
                          onClickAway={handleAttachmentMenuClose}
                        >
                          <Menu
                            id="attachment-popover"
                            open={attachmentMenuOpen}
                            anchorEl={document.getElementById(
                              "message-textfield"
                            )}
                            onClose={handleAttachmentMenuClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                          >
                            <MenuItem
                              onClick={(e) => {
                                handleAttachmentMenuClose(e);
                                open();
                              }}
                            >
                              <ListItemIcon>
                                <UploadFileIcon
                                  sx={{
                                    marginRight: "8px",
                                    color: theme.palette.primary.contrastText,
                                  }}
                                />
                              </ListItemIcon>
                              <Typography variant="caption">
                                Upload files from your device
                              </Typography>
                            </MenuItem>
                            <MenuItem
                              sx={{ justifyItems: "center" }}
                              onClick={(e) => {
                                handleAttachmentMenuClose(e);
                                handleSelectFileFromExisting();
                              }}
                            >
                              <ListItemIcon>
                                <CloudUploadIcon
                                  sx={{
                                    marginRight: "8px",
                                    color: theme.palette.primary.contrastText,
                                  }}
                                />
                              </ListItemIcon>
                              <Typography variant="caption">
                                Attach files you've already uploaded
                              </Typography>
                            </MenuItem>
                            {hasFileSystemAccess && (
                              <MenuItem
                                variant="contained"
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  pr: 0,
                                }}
                                onClick={(e) => {
                                  handleLocalFileInput();
                                }}
                                disableRipple
                              >
                                <ListItemIcon>
                                  <PlaylistAddIcon
                                    sx={{
                                      marginRight: "8px",
                                      color: theme.palette.primary.contrastText,
                                    }}
                                  />
                                </ListItemIcon>

                                <input
                                  type="file"
                                  id="localFileInput"
                                  // webkitdirectory
                                  multiple
                                  onChange={handleLocalFileInput}
                                  style={{ display: "none" }}
                                />
                                <Tooltip title="Give Zippy the ability to access local text-based files on your computer without uploading them">
                                  <Typography variant="caption">
                                    Add local files to the conversation
                                  </Typography>
                                </Tooltip>

                                <ListItemIcon
                                  sx={{
                                    pl: 5,
                                    mr: 0,
                                    pr: 0,
                                    justifyContent: "end",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <Tooltip title="Edit attached local files">
                                    <EditNoteIcon
                                      id="local-file-list-icon"
                                      sx={{
                                        color: theme.palette.primary.light,
                                        marginRight: "8px",
                                        display:
                                          localFileList.length > 0
                                            ? "block"
                                            : "none",
                                      }}
                                      onClick={
                                        localFileList.length > 0
                                          ? handleOpenLocalFileList
                                          : null
                                      }
                                    />
                                  </Tooltip>
                                  <ClickAwayListener
                                    onClickAway={handleCloseLocalFileList}
                                  >
                                    <Menu
                                      anchorEl={document.getElementById(
                                        "local-file-list-icon"
                                      )}
                                      open={localFileListOpen}
                                      onClose={handleCloseLocalFileList}
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      {localFileList.map((fileName, index) => (
                                        <MenuItem
                                          key={index}
                                          sx={{
                                            pl: 0,
                                            pb: 0,
                                            pt: 0,
                                            mt: 0,
                                            mb: 0,
                                            ml: 0,
                                          }}
                                        >
                                          <Tooltip title="Remove file">
                                            <IconButton
                                              size="small"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                removeLocalFileFromList(
                                                  fileName
                                                );
                                              }}
                                              sx={{ ml: 1 }}
                                              disableRipple
                                            >
                                              <CloseIcon
                                                sx={{
                                                  color:
                                                    theme.palette.error.main,
                                                }}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                          <Typography variant="caption">
                                            {fileName}
                                          </Typography>
                                        </MenuItem>
                                      ))}
                                    </Menu>
                                  </ClickAwayListener>
                                </ListItemIcon>
                              </MenuItem>
                            )}
                          </Menu>
                        </ClickAwayListener>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          alignItems: "flex-end",
                          alignSelf: "flex-end",
                          display: "contents",
                          p: 0,
                          m: 0,
                        }}
                      >
                        <Button
                          type="submit"
                          disabled={isStreaming}
                          onClick={(e) => {
                            e.preventDefault();
                            onSubmit();
                          }}
                          id="submit-button"
                          variant="contained"
                          sx={{ height: "30px" }}
                        >
                          <BsFillSendFill />
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default Conversations;
