import React, { useState, useEffect, useContext } from "react";
import { Box, Button, Menu, MenuItem, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PsychologyIcon from "@mui/icons-material/Psychology";
import { useConversationsApi } from "../../../api/conversationsApi";
import { ConversationContext } from "../providers/ConversationProvider";

export default function ModelSelectionMenu(props) {
  const { sx } = props;

  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [supportedModels, setSupportedModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [conversationDetails, setConversationDetails] = useState(undefined);

  const { selectedConversationRef } = useContext(ConversationContext);

  const {
    getConversationDetails,
    updateConversationModelName,
    getSupportedModels,
    getDefaultModel,
  } = useConversationsApi();

  // Fetch supported models when the component mounts
  useEffect(() => {
    async function updateModelList() {
      try {
        const models = await getSupportedModels();
        const defaultModel = await getDefaultModel();

        setSupportedModels(models.models);
        if (models.models.length > 0) {
          if (
            conversationDetails === undefined ||
            conversationDetails.modelConfiguration === undefined
          ) {
            setSelectedModel(defaultModel); // Set default selected model
          } else {
            setSelectedModel(conversationDetails.modelConfiguration.model); // Set default selected model
          }
        }
      } catch (error) {
        console.error("Error fetching supported models:", error);
      }
    }

    // Only get the default and supported models if we don't already have them
    if (supportedModels.length === 0 || selectedModel === "") {
      updateModelList();
    }
  }, [conversationDetails]);

  // Update the conversation details when the conversationId changes
  useEffect(() => {
    async function updateModelList() {
      try {
        let conversationDetails;
        if (selectedConversationRef.current === "new") {
          conversationDetails = undefined;
        } else {
          // console.log(
          //   "Fetching conversation details for:",
          //   selectedConversationRef.current
          // );
          conversationDetails = await getConversationDetails(
            selectedConversationRef.current
          );
        }

        setConversationDetails(conversationDetails);
      } catch (error) {
        console.error("Oh no! Error fetching conversation details:", error);
      }
    }

    updateModelList();
  }, [selectedConversationRef.current, selectedModel]);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModelSelect = (model) => {
    setSelectedModel(model);
    setConversationDetails((prev) => ({
      ...prev,
      modelConfiguration: { model: model },
    }));
    updateConversationModelName(selectedConversationRef.current, model);

    handleClose();
  };

  return (
    <Box sx={sx}>
      <Tooltip title={"Zippy running on: " + selectedModel}>
        <PsychologyIcon
          onClick={handleClick}
          color={theme.palette.primary.contrastText}
          sx={{
            width: "35px",
            ":hover": { color: theme.palette.primary.light },
            cursor: "pointer",
          }}
        ></PsychologyIcon>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {supportedModels.map((model) => (
          <MenuItem
            key={model}
            onClick={() => handleModelSelect(model)}
            selected={model === selectedModel} // Highlight selected model
          >
            {model}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
