import { useCustomFetchNoAuth } from "./customFetch";
import { useCustomFetch } from "./customFetch";

const USER_API_HOST = `${window.env.REACT_APP_USER_API_HOST}`;

export const useUserApi = () => {
  const { customFetchNoAuth } = useCustomFetchNoAuth();
  const { customFetch } = useCustomFetch();

  const betaUserSignup = async (name, email, useCase) => {
    try {
      const response = await customFetchNoAuth(`${USER_API_HOST}/beta-signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          email: email,
          use_case: useCase,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to sign up for beta");
      }

      const response_json = await response.json();

      return response_json.message;
    } catch (error) {
      console.error("Error signing up for beta:", error);

      throw error;
    }
  };

  const createNewUser = async (userDetails) => {
    try {
      const response = await customFetch(`${USER_API_HOST}/user`, {
        method: "POST",
        credentials: "include", // include cookies
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userDetails),
      });

      if (!response.ok) {
        throw new Error("Failed to create new user");
      }

      const responseJson = await response.json();

      return responseJson;
    } catch (error) {
      console.error("Error creating new user:", error);

      throw error;
    }
  };

  const fetchBetaApplications = async () => {
    try {
      const response = await customFetch(`${USER_API_HOST}/beta-signup`, {
        method: "GET",
        credentials: "include", // include cookies
      });

      if (!response.ok) {
        throw new Error("Failed to fetch beta applications");
      }

      const responseJson = await response.json();

      return responseJson;
    } catch (error) {
      console.error("Error fetching beta applications:", error);

      throw error;
    }
  };

  const fetchUserDetails = async () => {
    try {
      const response = await customFetch(`${USER_API_HOST}/user/current`, {
        method: "GET",
        credentials: "include", // include cookies
      });

      if (!response.ok) {
        return null;
      }

      const userDetails = await response.json();

      return userDetails;
    } catch (error) {
      return null;
    }
  };

  const fetchAvatarUploadUrl = async (file_type) => {
    try {
      const url = `${USER_API_HOST}/user/avatar-upload-url?file_type=${encodeURIComponent(
        file_type
      )}`;
      const response = await customFetch(url, {
        method: "GET",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch avatar upload URL");
      }

      const responseJson = await response.json();

      return responseJson;
    } catch (error) {
      console.error("Error fetching avatar upload URL:", error);

      throw error;
    }
  };

  const uploadAvatar = async (file, uploadUrl) => {
    try {
      const response = await fetch(uploadUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
          "x-amz-acl": "public-read",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to upload avatar");
      }
    } catch (error) {
      console.error("Error uploading avatar:", error);

      throw error;
    }
  };

  const updateUserInfo = async (
    name,
    location,
    avatarFile,
    password = null
  ) => {
    let avatar_url = null;

    try {
      // If there is an avatar file, upload it
      if (avatarFile) {
        const avatarUploadDetails = await fetchAvatarUploadUrl(avatarFile.type);

        await uploadAvatar(avatarFile, avatarUploadDetails.upload_url);

        avatar_url = avatarUploadDetails.public_url;
      }
    } catch (error) {
      console.error("Error updating user avatar:", error);
      throw error;
    }

    const response = await customFetch(`${USER_API_HOST}/user/current`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        location,
        password,
        avatar_url,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to update user info");
    }
  };

  const fetchOAuthStatus = async (provider) => {
    try {
      const response = await customFetch(
        `${USER_API_HOST}/oauth-status/${provider}`,
        {
          method: "GET",
          credentials: "include", // include cookies
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch OAuth details");
      }

      const responseJson = await response.json();

      return responseJson;
    } catch (error) {
      console.error("Error fetching OAuth details:", error);

      throw error;
    }
  };

  return {
    betaUserSignup,
    createNewUser,
    fetchBetaApplications,
    fetchUserDetails,
    updateUserInfo,
    fetchOAuthStatus,
  };
};
