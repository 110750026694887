import {
  Box,
  Button,
  Checkbox,
  Container,
  List,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import AgentCard from "./AgentCard";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DraggableDialog from "../DraggableDialog";
import React, { useState, useEffect, useRef } from "react";
import ConfirmationDialog from "../ConfirmationDialog";

import { useConversationsApi } from "../../api/conversationsApi";

const Agents = () => {
  const theme = useTheme();
  const {
    getAvailableAgentCapabilities,
    getAgents,
    createAgent,
    deleteAgent,
    updateAgent,
    getSupportedModels,
  } = useConversationsApi();

  const [supportedModels, setSupportedModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [agentToDelete, setAgentToDelete] = useState(null);

  const [agents, setAgents] = useState([]);
  const [availableCapabilities, setAvailableCapabilities] = useState([]);

  useEffect(() => {
    getAvailableAgentCapabilities().then((response) => {
      setAvailableCapabilities(response);
    });

    getSupportedModels().then((response) => {
      setSupportedModels(response.models);
    });

    getAgents().then((response) => {
      setAgents(response);
    });
  }, []);

  const zippyAgent = {
    id: "zippy",
    name: "Zippy",
    description: "A general purpose AI agent",
    image_url: "/zippy.png",
    system_prompt: "How can I help you today?",
    capabilities: availableCapabilities.map((c) => c.name),
  };

  const [editAgentDialogOpen, setEditAgentDialogOpen] = useState(false);
  // const [editAgentDialogContent, setEditAgentDialogContent] = useState(null);
  const [editAgentDetails, setEditAgentDetails] = useState({
    name: "",
    description: "",
    system_prompt: "",
    role: "",
    capabilities: "",
    model_name: supportedModels[0],
  });

  // const cursorPositionRef = useRef({});

  const showDeleteAgentConfirmation = (agent) => {
    setAgentToDelete(agent);
    setOpenConfirmationDialog(true);
  };

  const onConfirmDeleteAgent = async () => {
    setOpenConfirmationDialog(false);

    // Delete the agent from the API
    await deleteAgent(agentToDelete.id);

    // Refetch the agents
    const response = await getAgents();
    setAgents(response);
  };

  const handleEditAgentChange = (field, value) => {
    setEditAgentDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const handleSaveAgent = async () => {
    if (!editAgentDetails) {
      return;
    }

    // If the agent has an ID, then we are updating an existing agent
    // If the agent does not have an ID, then we are creating a new agent
    if (editAgentDetails.id) {
      // Update the agent
      await updateAgent(
        editAgentDetails.id,
        editAgentDetails.name,
        editAgentDetails.description,
        editAgentDetails.system_prompt,
        editAgentDetails.role,
        editAgentDetails.capabilities,
        editAgentDetails.model_name,
        editAgentDetails.uses_memory
      );
    } else {
      // Create the agent
      await createAgent(
        editAgentDetails.name,
        editAgentDetails.description,
        editAgentDetails.system_prompt,
        editAgentDetails.role,
        editAgentDetails.capabilities,
        editAgentDetails.model_name,
        editAgentDetails.uses_memory
      );
    }

    setEditAgentDialogOpen(false);

    // Refetch the agents
    const response = await getAgents();
    setAgents(response);
  };

  const showEditAgentDialog = (agent) => {
    // If the agent is null, then we are adding a new agent
    if (!agent) {
      setEditAgentDetails({
        name: "",
        description: "",
        image_url: "",
        system_prompt: "",
        role: "",
        capabilities: [],
        model_name: supportedModels[0],
        uses_memory: true,
      });
    } else {
      setEditAgentDetails(agent);
    }
    setEditAgentDialogOpen(true);
  };

  useEffect(() => {
    if (!editAgentDetails) {
      return;
    }
  }, [editAgentDetails, availableCapabilities]);

  return (
    <Container
      disableGutters
      id="agents-container"
      maxWidth={false}
      sx={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        color: theme.palette.text.primary,
        height: "calc(100dvh - 64px)",
      }}
    >
      {/* Header box that explains what the agents are, as well as a button to add an agent */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          bgcolor: theme.palette.primary.main,
          color: theme.palette.secondary.contrastText,
        }}
      >
        <Typography variant="h5" component="h1">
          Agents
          <Typography variant="body2" component="p">
            Agents are specialized AI bots that can be used to perform various
            tasks.
          </Typography>
        </Typography>

        <Button
          variant="contained"
          color="secondary"
          startIcon={<PersonAddIcon />}
          onClick={() => showEditAgentDialog(null)}
        >
          Add Agent
        </Button>
      </Box>

      {/* Render the list of agents */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          p: 1,
          m: 1,
          borderRadius: 2,
          justifyContent: "center",
        }}
      >
        <AgentCard
          agent={zippyAgent}
          informationText="When Zippy is alone in a conversation, it will use all of the available capabilities listed here.  When Zippy is participating in a conversation with other agents, it will act as a coordinator and not perform any tasks itself."
          availableCapabilities={availableCapabilities}
        />
        {agents.map((agent, index) => (
          <AgentCard
            agent={agent}
            onEditAgent={showEditAgentDialog}
            onDeleteAgent={showDeleteAgentConfirmation}
            availableCapabilities={availableCapabilities}
          />
        ))}
      </Box>

      {/* Dialog for editing an agent */}
      <DraggableDialog
        open={editAgentDialogOpen}
        onClose={() => setEditAgentDialogOpen(false)}
        title="Edit Agent"
        content={
          <Box sx={{ margin: 1, display: "flex", flexDirection: "column" }}>
            <TextField
              label="Agent Name"
              value={editAgentDetails.name}
              onChange={(e) => handleEditAgentChange("name", e.target.value)}
              sx={{ margin: 1, minWidth: "300px" }}
            />
            <Select
              label="Model"
              value={editAgentDetails.model_name}
              onChange={(e) =>
                handleEditAgentChange("model_name", e.target.value)
              }
              sx={{ margin: 1, minWidth: "300px" }}
            >
              {supportedModels.map((model) => (
                <MenuItem value={model}>{model}</MenuItem>
              ))}
            </Select>
            <Stack direction="row">
              <Checkbox
                label="Uses Memory"
                checked={editAgentDetails.uses_memory}
                onChange={(e) =>
                  handleEditAgentChange("uses_memory", e.target.checked)
                }
                sx={{ p: 0, mx: 1, my: 0.5 }}
              />
              <Typography
                sx={{
                  margin: 1,
                }}
              >
                Agent Uses Memory
              </Typography>
            </Stack>
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.warning.light,
                margin: 1,
                fontStyle: "italic",
              }}
            >
              Agents that use memory will be able to remember information from
              previous interactions with users. This can be useful for
              maintaining context in a conversation without Zippy having to
              provide that context with each query.
            </Typography>
            <TextField
              label="Description"
              value={editAgentDetails.description}
              onChange={(e) =>
                handleEditAgentChange("description", e.target.value)
              }
              sx={{ margin: 1, minWidth: "300px" }}
            />
            <TextField
              label="Role"
              value={editAgentDetails.role}
              multiline
              onChange={(e) => handleEditAgentChange("role", e.target.value)}
              sx={{ margin: 1, minWidth: "300px" }}
            />
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.warning.light,
                margin: 1,
                fontStyle: "italic",
              }}
            >
              The role of the Agent is used to determine how the coordinator
              will assign tasks agents. Agents will only be assigned tasks that
              match their role.
            </Typography>
            <TextField
              label="System Prompt"
              value={editAgentDetails.system_prompt}
              multiline
              onChange={(e) =>
                handleEditAgentChange("system_prompt", e.target.value)
              }
              sx={{ margin: 1, minWidth: "300px" }}
            />
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.warning.light,
                margin: 1,
                fontStyle: "italic",
              }}
            >
              The more detailed the system prompt, the better the agent will
              perform. Be specific and detailed in your instructions, and the
              agent will be able to better understand and respond to user input.
            </Typography>
            <List>
              {availableCapabilities.map((capability) => (
                <Box
                  key={capability.name}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Checkbox
                    checked={editAgentDetails.capabilities.includes(
                      capability.name
                    )}
                    onChange={(e) =>
                      handleEditAgentChange(
                        "capabilities",
                        e.target.checked
                          ? [...editAgentDetails.capabilities, capability.name]
                          : editAgentDetails.capabilities.filter(
                              (name) => name !== capability.name
                            )
                      )
                    }
                    sx={{ p: 0, mx: 1, my: 0.5 }}
                  />
                  <ListItemText
                    primary={capability.display_name}
                    secondary={capability.description}
                  />
                </Box>
              ))}
            </List>

            <Typography
              variant="caption"
              sx={{
                color: theme.palette.warning.light,
                margin: 1,
                fontStyle: "italic",
              }}
            >
              The fewer the number of capabilities, the more specialized the
              agent will be. Agents with more capabilities may be slower to
              respond to user input.
            </Typography>
          </Box>
        }
        actions={
          <div>
            <Button autoFocus onClick={() => setEditAgentDialogOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleSaveAgent}>Save</Button>
          </div>
        }
      />
      <ConfirmationDialog
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        title={"Delete Agent"}
        content={
          "Are you sure you want to delete this agent?  It will also be removed from any conversations that it is currently active in."
        }
        onConfirm={onConfirmDeleteAgent}
      />
    </Container>
  );
};

export default Agents;
