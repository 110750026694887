import {
  Box,
  Card,
  CardContent,
  Typography,
  List,
  Button,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { throttle } from "lodash";
import AiMessageItem from "./AiMessageItem";
import MessagehistoryItem from "./MessageHistoryItem";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";

import { ConversationContext } from "../providers/ConversationProvider";

const MessageList = ({
  conversationId,
  isThinking,
  isSendingMessage,
  theme,
  drawerWidth,
  onSubmitMessage,
}) => {
  const { status, messageHistory, setMessageHistory, currentMessage } =
    useContext(ConversationContext);

  const [isAutoScroll, setIsAutoScroll] = useState(true);
  const messageListRef = useRef(null);

  const handleScroll = throttle(() => {
    const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
    if (scrollTop + clientHeight + 100 >= scrollHeight) {
      setIsAutoScroll(true);
    } else if (scrollTop + clientHeight < scrollHeight - 100) {
      setIsAutoScroll(false);
    }
  }, 50);

  useEffect(() => {
    const messageList = messageListRef.current;
    messageList.addEventListener("scroll", handleScroll);
    return () => messageList.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (isAutoScroll) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [currentMessage, isAutoScroll]);

  const cards = [
    "What can you do?",
    "What time is it in Tokyo, NY, and Hawaii?",
    "Give me 5 top-rated restaurants nearby",
    "Describe the main image on https://apnews.com/",
  ];

  return (
    <List
      sx={{
        flexGrow: 1,
        overflowY: "auto",
        marginBottom: "25px", // Adjust space for the text input
        width: "100%",
        maxHeight: "calc(100vh - 64px)", // Adjust height for app menu
      }}
      id="message-list"
      ref={messageListRef}
    >
      {(messageHistory && messageHistory.length > 0) || currentMessage ? (
        <>
          {messageHistory.map((msg, index) => (
            <MessagehistoryItem
              key={"message-" + (msg.id ? msg.id : index)}
              id={"message-" + (msg.id ? msg.id : index)}
              conversationId={conversationId}
              msg={msg}
              index={index}
              theme={theme}
              sidebarSize={drawerWidth}
              setMessageHistory={setMessageHistory}
            />
          ))}
          {currentMessage && (
            <AiMessageItem
              key={
                "current-message-" +
                (currentMessage.id ? currentMessage.id : "current")
              }
              id={
                "current-message-" +
                (currentMessage.id ? currentMessage.id : "current")
              }
              conversationId={conversationId}
              msg={currentMessage}
              status={status}
              index={messageHistory.length + 1}
              isThinking={isThinking}
              isSendingMessage={isSendingMessage}
              theme={theme}
              sidebarSize={drawerWidth}
            />
          )}
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            maxWidth: { sm: "calc(100dvw - " + drawerWidth + "px)" },
            // bgcolor: "red",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              color: theme.palette.primary.light,
              margin: "10px",
              alignItems: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: "100px",
              }}
            >
              <ArrowBackTwoToneIcon />
              <Typography variant="body1" textAlign={"center"} sx={{ p: 1 }}>
                Select an existing conversation from the list
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              color: theme.palette.primary.light,
              margin: "10px",
              alignItems: "flex-start",
            }}
          >
            <Typography variant="body1" textAlign={"center"} sx={{ p: 1 }}>
              Or try one of the following queries:
            </Typography>
            <Box
              sx={{
                display: "flex",
                overflowX: "scroll",
                // border: 1,
                borderRadius: "5px",
                bgcolor: theme.palette.background.light,
                maxWidth: {
                  sm: "calc(98dvw - " + drawerWidth + "px)",
                  xs: "90vw",
                },
              }}
            >
              {/* Map over the card list and display them */}
              {cards.map((card, index) => (
                <Card
                  sx={{
                    m: 2,
                    minWidth: 200,
                    bgcolor: theme.palette.background.paper,
                    color: theme.palette.primary.light,
                    maxHeight: "75px",
                    alignContent: "center",
                  }}
                  key={index}
                >
                  <CardContent
                    sx={{
                      alignContent: "center",
                      alignItems: "center",
                      justifyItems: "center",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        onSubmitMessage(card);
                      }}
                    >
                      {card}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </List>
  );
};

export default MessageList;
