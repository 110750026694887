import * as React from "react";
import Box from "@mui/material/Box";
import PsychologyIcon from "@mui/icons-material/Psychology";

import {
  Alert,
  AlertTitle,
  Avatar,
  Checkbox,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useConversationsApi } from "../../../api/conversationsApi";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { ConversationContext } from "../providers/ConversationProvider";
import { Link } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

export default function AgentDrawer(props) {
  const theme = useTheme();

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => {
      console.log("swiped right");
      toggleDrawer(false);
    },
  });

  const { selectedConversationRef, conversationAgents, setConversationAgents } =
    useContext(ConversationContext);

  const {
    getAgents,
    addConversationAgent,
    removeConversationAgent,
    getOrchestrationConfiguration,
    updateOrchestrationConfiguration,
  } = useConversationsApi();

  const { sx, activeAgentsOpen, setActiveAgentsOpen, ...otherProps } = props;

  const [agents, setAgents] = useState([]);

  const [orchestrationConfiguration, setOrchestrationConfiguration] = useState({
    allow_user_goal_evaluation: false,
    allow_user_plan_evaluation: false,
    max_plan_execution_iterations: 1,
    allow_direct_answer: true,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setActiveAgentsOpen(open);
  };

  useEffect(() => {
    getAgents().then((response) => {
      setAgents(response);
    });
  }, [conversationAgents]);

  useEffect(() => {
    getOrchestrationConfiguration().then((response) => {
      setOrchestrationConfiguration(response);
    });
  }, []);

  const handleOrchestrationConfigurationUpdate = async (
    updatedOrchestrationConfiguration
  ) => {
    await updateOrchestrationConfiguration(updatedOrchestrationConfiguration);
    setOrchestrationConfiguration(updatedOrchestrationConfiguration);
  };

  const handleAgentSelection = (agent) => {
    // Check to see if the conversationAgents list includes an agent with the same id
    // If the agent is already in the list, remove it
    // If the agent is not in the list, add it
    // The agent in the list is an object, not just an id
    const agentIndex = conversationAgents.findIndex(
      (conversationAgent) => conversationAgent.id === agent.id
    );

    if (agentIndex > -1) {
      // Remove the agent from the conversation using the API (if there's a conversation)
      if (
        selectedConversationRef.current &&
        selectedConversationRef.current !== "new"
      ) {
        removeConversationAgent(selectedConversationRef.current, agent.id);
      }

      // Remove the agent from the conversation locally
      setConversationAgents((prevAgents) => {
        return prevAgents.filter((conversationAgent) => {
          return conversationAgent.id !== agent.id;
        });
      });
    } else {
      // Add the agent to the conversation using the API (if there's a conversation)
      if (
        selectedConversationRef.current &&
        selectedConversationRef.current !== "new"
      ) {
        addConversationAgent(selectedConversationRef.current, agent.id);
      }

      // Add the agent to the conversation locally
      setConversationAgents((prevAgents) => {
        return [...prevAgents, agent];
      });
    }
  };

  const content = () => (
    <Box
      sx={{
        sx,
        maxWidth: "90dvw",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          bgcolor: theme.palette.primary.main,
        }}
      >
        <Typography variant="h5" component="h1">
          Active Agents
          <Typography variant="body2" component="p">
            Agents are specialized AI bots that can be used to perform various
            tasks.
          </Typography>
        </Typography>
      </Box>

      <Box
        sx={{
          pl: 2,
          pr: 2,
          pt: 1,
        }}
      >
        <Alert variant="outlined" severity="info" sx={{ maxWidth: "500px" }}>
          <AlertTitle>About Agents</AlertTitle>
          Below, you can select the agents that you would like to participate in
          this conversation. Agents can have their own tools, knowledge, and
          capabilities. Conversations benefit from using agents when there are
          numerous complex tasks that need to be completed.
          <br />
          <br />
          <Typography variant="caption">
            Selected agents apply only to the current conversation.
          </Typography>
        </Alert>
        <Typography
          variant="caption"
          paragraph
          sx={{ fontStyle: "italic", textAlign: "end", pr: 2 }}
        >
          You can create or edit agents in the <Link to="/agents">Agents</Link>{" "}
          section.
        </Typography>
      </Box>

      <Box>
        <Typography
          variant="caption"
          sx={{
            fontStyle: "italic",
            color: theme.palette.warning.light,
            my: 1,
          }}
        ></Typography>
        <List sx={{ maxWidth: "500px" }}>
          <ListItem key={"agent-zippy"}>
            <Checkbox key="agent-checkbox-zippy" checked={true} disabled />
            <Avatar
              key="agent-avatar-zippy"
              alt="Zippy"
              src="/zippy.png"
              sx={{ mr: 1, bgcolor: theme.palette.secondary.light }}
            >
              Z
            </Avatar>
            <ListItemText
              key="agent-text-zippy"
              primary="Zippy"
              secondary="Zippy is always active in conversations, coordinating other agents or providing answers itself."
            />
          </ListItem>
          {agents.map((agent, index) => (
            <ListItem key={"agent-" + index}>
              <Checkbox
                key={"agent-checkbox-" + index}
                value={agent.id}
                label={agent.name}
                checked={conversationAgents.some(
                  (conversationAgent) => conversationAgent.id === agent.id
                )}
                onChange={() => handleAgentSelection(agent)}
              />
              <Avatar
                key={"agent-avatar-" + index}
                alt={agent.name}
                src={agent.image_url}
                sx={{ mr: 1, bgcolor: theme.palette.secondary.light }}
              >
                {agent.name[0]}
              </Avatar>
              <ListItemText
                key={"agent-text-" + index}
                primary={agent.name}
                secondary={agent.description}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <div {...swipeHandlers}>
      <Drawer
        id="active-agents-drawer"
        key={"active-agents-drawer"}
        anchor={"right"}
        open={activeAgentsOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {content()}
      </Drawer>
    </div>
  );
}
