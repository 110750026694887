import React from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  useTheme,
  Collapse,
  Typography,
  CircularProgress,
} from "@mui/material";
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CheckBoxOutlineBlankTwoToneIcon from "@mui/icons-material/CheckBoxOutlineBlankTwoTone";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";

const Status = ({ status }) => {
  const theme = useTheme();
  return (
    <List dense disablePadding>
      {Object.keys(status).length > 0 &&
        Object.entries(status).map(([agent_name, statusData]) => (
          <React.Fragment key={agent_name}>
            <ListItem disableGutters disablePadding>
              <Typography
                variant="h6"
                sx={{ color: theme.palette.primary.light }}
              >
                {agent_name}
              </Typography>
            </ListItem>
            <Collapse in={true} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {statusData.map((statusDataItem, index) => (
                  <ListItem key={index} disableGutters disablePadding>
                    <ListItemAvatar>
                      <Avatar sx={{ background: "transparent" }}>
                        {statusDataItem.status === "in_progress" ? (
                          <>
                            <BuildCircleIcon
                              sx={{ color: theme.palette.primary.light }}
                            />
                            <CircularProgress
                              size={30}
                              thickness={5}
                              sx={{
                                color: theme.palette.success.main,
                                position: "absolute",
                                top: 5,
                                left: 5,
                                zIndex: 1,
                              }}
                            />
                          </>
                        ) : statusDataItem.status === "success" ? (
                          <CheckBoxTwoToneIcon
                            sx={{ color: theme.palette.success.light }}
                          />
                        ) : statusDataItem.status === "warning" ? (
                          <ReportProblemIcon
                            sx={{ color: theme.palette.warning.main }}
                          />
                        ) : statusDataItem.status === "error" ? (
                          <ReportProblemIcon
                            sx={{ color: theme.palette.error.main }}
                          />
                        ) : (
                          <CheckBoxOutlineBlankTwoToneIcon
                            sx={{ color: theme.palette.primary.light }}
                          />
                        )}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={statusDataItem.operationName}
                      primaryTypographyProps={{
                        variant: "caption",
                      }}
                      secondary={statusDataItem.description || ""}
                      secondaryTypographyProps={{
                        variant: "caption",
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
    </List>
  );
};

export default Status;
