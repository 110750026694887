import React from "react";
import { useState, useMemo } from "react";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AppMenu from "./components/appMenu/AppMenu";
import { AuthProvider } from "./context/AuthContext";
import AboutPage from "./components/about/About";
import Logout from "./components/auth/Logout";
import getTheme from "./themes";
import Login from "./components/auth/Login";
import Conversations from "./components/conversations/Conversations";
import { ConversationProvider } from "./components/conversations/providers/ConversationProvider";
import useMediaQuery from "@mui/material/useMediaQuery";
import UserProfile from "./components/profile/UserProfile";
import Documents from "./components/documents/Documents";
import Integrations from "./components/integrations/Integrations";
import AdminRoute from "./components/AdminRoute";
import Administration from "./components/administration/Administration";
import Agents from "./components/agents/Agents";

function App() {
  const localMode = localStorage.getItem("color-mode");
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [mode, setMode] = useState(localMode || "light");
  const [theme, setTheme] = useState(getTheme(mode));

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        if (!localMode && !prefersDarkMode) {
          localStorage.setItem("color-mode", "light");
        } else if (!localMode && prefersDarkMode) {
          localStorage.setItem("color-mode", "dark");
        }

        setMode((prevMode) => {
          const newMode = prevMode === "light" ? "dark" : "light";
          //Save the new mode instead of the previous one
          localStorage.setItem("color-mode", newMode);
          setTheme(getTheme(newMode));
          return newMode;
        });
      },
    }),
    []
  );

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <AppMenu mode={mode} toggleColorMode={colorMode.toggleColorMode}>
          <Routes>
            <Route path="/" element={<AboutPage />} />

            <Route path="/login" element={<Login />} />

            {/* Wrap both conversation routes in a single provider */}
            <Route
              path="/conversations/*"
              element={
                <ProtectedRoute>
                  <ConversationProvider>
                    <Routes>
                      <Route path=":id" element={<Conversations />} />
                      <Route path="" element={<Conversations />} />
                    </Routes>
                  </ConversationProvider>
                </ProtectedRoute>
              }
            />

            <Route
              path="/agents"
              element={
                <ProtectedRoute>
                  <Agents />
                </ProtectedRoute>
              }
            />

            <Route
              path="/documents"
              element={
                <ProtectedRoute>
                  <Documents />
                </ProtectedRoute>
              }
            />
            <Route
              path="/integrations"
              element={
                <ProtectedRoute>
                  <Integrations mode={mode} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <UserProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/administration"
              element={
                <AdminRoute>
                  <Administration />
                </AdminRoute>
              }
            />
            <Route
              path="/logout"
              element={
                <ProtectedRoute>
                  <Logout />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </AppMenu>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
