import { useEffect, useState } from "react";
import { useConversationsApi } from "../../../api/conversationsApi";

const useWebsockets = (
  handleMessageIdData,
  handleStatusData,
  handleLocalFileData
) => {
  const [webSocketControl, setWebSocketControl] = useState(null);
  const [webSocketStateUpdated, setWebSocketStateUpdated] = useState(0);

  const { setupWebSocket } = useConversationsApi();

  useEffect(() => {
    const initializeWebSocket = async () => {
      // If there is already a websocket connection, close it
      try {
        if (webSocketControl) {
          webSocketControl.close();
        }
      } catch (error) {
        console.error(
          "Error closing websocket as we're opening a new one: ",
          error
        );
      }

      await setupWebSocket(
        handleWebSocketOpen,
        handleWebSocketMessage,
        handleWebSocketClose
      );
    };

    if (!webSocketControl) {
      initializeWebSocket();
    }
  }, [webSocketStateUpdated]); //selectedConversation,

  const handleWebSocketOpen = (websocket, event) => {
    setWebSocketControl(websocket);
  };

  const handleWebSocketClose = (ev) => {
    if (ev.code !== 4002 && ev.code !== 1001) {
      console.error(
        "Websocket connection closed with code '" +
          ev.code +
          "', attempting again in 3 seconds..."
      );
      //  Wait for 3 seconds before attempting the connection again
      setTimeout(() => {
        setWebSocketControl(null);
        requestUpdateWebSocketState();
      }, 3000);
    } else {
      setWebSocketControl(null);
    }
  };

  const handleWebSocketMessage = (message) => {
    try {
      const messageJson = JSON.parse(message);
      if (messageJson.type === "StatusData") {
        handleStatusData(messageJson.data);
      } else if (messageJson.type === "MessageIdData") {
        handleMessageIdData(messageJson.data);
      } else if (messageJson.type === "LocalFileData") {
        // console.log("LocalFileData", messageJson.data);
        handleLocalFileData(messageJson.data);
      } else {
        console.error("Unknown message type: ", messageJson);
      }
    } catch (error) {
      console.error("Didn't understand ws message: ", message, error);
    }
  };

  const requestUpdateWebSocketState = () => {
    setWebSocketStateUpdated((prev) => {
      if (typeof prev !== "number" || isNaN(prev)) {
        return 0;
      }
      return prev + 1;
    });
  };

  return {
    handleWebSocketOpen,
    handleWebSocketClose,
    handleWebSocketMessage,
    requestUpdateWebSocketState,
    webSocketControl,
  };
};

export { useWebsockets };
