import React, { createContext, useState } from "react";
import { useEffect, useRef } from "react";
import useTime from "../hooks/useTime";
import { useConversationsApi } from "../../../api/conversationsApi";

const ConversationContext = createContext();

function ConversationProvider({ conversationId, children }) {
  const [selectedConversation, setConversationId] = useState(
    conversationId || "new"
  );

  const selectedConversationRef = useRef(selectedConversation);
  const [refreshConversationListTrigger, setRefreshConversationListTrigger] =
    useState(0);
  const [refreshMessagesTrigger, setRefreshMessagesTrigger] = useState(0);

  const [conversations, setConversations] = useState([]);

  const [isStreaming, setIsStreaming] = useState(false);

  const [messageHistory, setMessageHistory] = useState([]);
  const [currentMessage, setCurrentMessage] = useState(null);

  const [status, setStatus] = useState({});
  const [statusOpen, setStatusOpen] = useState(false);

  const [conversationAgents, setConversationAgents] = useState([]);

  const { getConversations, getConversationMessages, getConversationAgents } =
    useConversationsApi();

  const [conversationsWithHeadings, setConversationsWithHeadings] = useState(
    []
  );
  const [selectedConversationSection, setSelectedConversationSection] =
    useState("Today");

  const { getLocalTime } = useTime();

  const [isLoading, setIsLoading] = useState(false);
  const [waitingForConversation, setWaitingForConversation] = useState(false);

  const [localFileList, setLocalFileList] = useState([]);
  const [localFileHandles, setLocalFileHandles] = useState({});

  const fetchConversations = async () => {
    try {
      const fetchedConversations = await getConversations();
      // Check if fetchedConversations is different from current conversations
      setConversations((prevConversations) => {
        if (
          JSON.stringify(prevConversations) !==
          JSON.stringify(fetchedConversations)
        ) {
          return fetchedConversations;
        }

        return prevConversations;
      });

      const withHeadings = fetchedConversations.map((conversation) => ({
        ...conversation,
        sectionHeading: getSectionHeading(conversation),
      }));
      // Check if withHeadings is different from current conversationsWithHeadings
      setConversationsWithHeadings((prevWithHeadings) => {
        if (JSON.stringify(prevWithHeadings) !== JSON.stringify(withHeadings)) {
          return withHeadings;
        }
        return prevWithHeadings;
      });
    } catch (error) {
      console.error("Failed to fetch conversations", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchConversations();
  }, [refreshConversationListTrigger]);

  // useEffect to handle when refreshMessagesTrigger changes
  useEffect(() => {
    if (selectedConversation && selectedConversation !== "new") {
      getConversationMessages(selectedConversation).then((fetchedMessages) => {
        if (!fetchedMessages) {
          // This means the conversation does not exist (or is deleted)
          // Reset everything
          console.error("Conversation does not exist");
          setSelectedConversation("new");
          setMessageHistory([]);
          return;
        }

        setMessageHistory(fetchedMessages);
      });
    }
  }, [refreshMessagesTrigger]);

  function setSelectedConversation(conversationId) {
    if (
      selectedConversationRef.current !== "new" &&
      selectedConversationRef.current !== undefined &&
      selectedConversationRef.current !== conversationId
    ) {
      // console.log("Clearing conversation state blah");
      clearConversationState();
    }
    // console.log("Setting selected conversation", conversationId);
    setConversationId(conversationId);
    selectedConversationRef.current = conversationId;
  }

  const fetchMessages = async (conversationId) => {
    setIsLoading(true);
    try {
      const fetchedMessages = await getConversationMessages(conversationId);
      setMessageHistory(fetchedMessages);
    } catch (error) {
      console.error("Failed to fetch messages", error);
      setSelectedConversation("new");
      setIsLoading(false);
      setMessageHistory([]);
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const getSectionHeading = (conversation) => {
    // Get the browser's time zone offset in minutes
    const recordModifiedLocal = getLocalTime(conversation.record_modified);

    // Resetting hours, minutes, seconds, and milliseconds to 0 for the local date
    const recordModified = new Date(recordModifiedLocal.setHours(0, 0, 0, 0));
    const currentDate = new Date(new Date().setHours(0, 0, 0, 0));

    const daysDifference = Math.floor(
      (currentDate - recordModified) / (1000 * 60 * 60 * 24)
    );
    let sectionHeading = "";

    if (daysDifference === 0) {
      sectionHeading = "Today";
    } else if (daysDifference >= 1 && daysDifference <= 7) {
      sectionHeading = "Previous 7 days";
    } else if (
      recordModified.getMonth() === currentDate.getMonth() &&
      recordModified.getFullYear() === currentDate.getFullYear()
    ) {
      sectionHeading = "This month";
    } else {
      // If the conversation is from a previous year, just group it under "Previous"
      if (recordModified.getFullYear() < currentDate.getFullYear()) {
        sectionHeading = "Older";
      } else {
        // Otherwise, group it under the month and year
        sectionHeading = recordModified.toLocaleString("default", {
          month: "long",
          year: "numeric",
        });
      }
    }
    return sectionHeading;
  };

  // Update the ref whenever selectedConversation changes
  useEffect(() => {
    // Clear the status if the conversation changes
    setStatus({});

    // Clear the current message if the conversation changes
    // if (
    //   selectedConversationRef.current &&
    //   selectedConversationRef.current !== "new" &&
    //   selectedConversationRef.current !== undefined
    // ) {
    //   setCurrentMessage(null);
    // }

    // Update the ref
    selectedConversationRef.current = selectedConversation;

    // Fetch the conversation agents if the selected conversation is not new
    if (
      selectedConversation &&
      selectedConversation !== "new" &&
      selectedConversation !== undefined
    ) {
      getConversationAgents(selectedConversation)
        .then((response) => {
          setConversationAgents(response);
        })
        .catch((error) => {
          console.error("Failed to fetch conversation agents", error);
          setConversationAgents([]);
        });
    }
  }, [selectedConversation]);

  useEffect(() => {
    if (waitingForConversation) {
      // Change the URL if the selected conversation is not the same as the conversationId, and it's not null, undefined, or new
      if (
        selectedConversation !== conversationId &&
        selectedConversation !== null &&
        selectedConversation !== undefined &&
        selectedConversation !== "new"
      ) {
        changeUrl(selectedConversation);

        // Also refresh the conversation list
        requestConversationListRefresh();
      }

      return;
    }

    if (selectedConversation === null || selectedConversation === undefined) {
      setSelectedConversation("new");
    } else if (selectedConversation === "new") {
      setSelectedConversationSection("Today");
      setMessageHistory([]);
    } else if (selectedConversation !== "new") {
      fetchMessages(selectedConversation)
        .then(() => {
          changeUrl(selectedConversation);
        })
        .catch((error) => {
          console.error("Failed to fetch messages", error);
          setSelectedConversation("new");
        });
    }
  }, [selectedConversation]);

  const startNewConversation = () => {
    // Then update the state
    setSelectedConversation("new");
    // Clear other related state
    clearConversationState();
  };

  const clearConversationState = () => {
    // console.log("Clearing conversation state");
    setMessageHistory([]);
    setLocalFileHandles({});
    setLocalFileList([]);
    setCurrentMessage(null);
    setStatus({});
    setConversationAgents([]);
    setIsStreaming(false);
  };

  const changeUrl = (selectedConversation) => {
    if (selectedConversation !== conversationId) {
      window.history.replaceState(
        null,
        "",
        `/conversations/${selectedConversation}`
      );

      // Also refresh the conversation list
      // requestConversationListRefresh();
    }
  };

  const findConversationSection = (conversationId) => {
    const section = conversationsWithHeadings.find(
      (conversation) => conversation.id === conversationId
    )?.sectionHeading;

    return section;
  };

  useEffect(() => {
    if (
      selectedConversation === "new" ||
      selectedConversation === null ||
      selectedConversation === undefined
    ) {
      return;
    }

    const selectedConversationSection =
      findConversationSection(selectedConversation);

    setSelectedConversationSection(selectedConversationSection);
  }, [conversationsWithHeadings]);

  useEffect(() => {
    if (conversationId) {
      setSelectedConversation(conversationId);
    }
  }, [conversationId]);

  const requestConversationListRefresh = () => {
    setRefreshConversationListTrigger((prev) => {
      if (typeof prev !== "number" || isNaN(prev)) {
        console.error(
          "Unexpected state type for refreshConversationListTrigger:",
          prev
        );
        return 0; // Reset to a valid state
      }
      const val = prev + 1;
      return val;
    });
  };

  const requestMessagesRefresh = () => {
    setRefreshMessagesTrigger((prev) => {
      if (typeof prev !== "number" || isNaN(prev)) {
        console.error(
          "Unexpected state type for refreshMessagesTrigger:",
          prev
        );
        return 0; // Reset to a valid state
      }
      const val = prev + 1;
      return val;
    });
  };

  return (
    <ConversationContext.Provider
      value={{
        status,
        setStatus,
        setStatusOpen,
        statusOpen,
        conversations,
        conversationsWithHeadings,
        messageHistory,
        setMessageHistory,
        currentMessage,
        setCurrentMessage,
        conversationAgents,
        setConversationAgents,
        isLoading,
        waitingForConversation,
        setWaitingForConversation,
        fetchConversations,
        fetchMessages,
        startNewConversation,
        clearConversationState,
        selectedConversationSection,
        selectedConversation,
        selectedConversationRef,
        setSelectedConversation,
        refreshConversationListTrigger,
        requestConversationListRefresh,
        refreshMessagesTrigger,
        requestMessagesRefresh,
        changeUrl,
        localFileList,
        setLocalFileList,
        localFileHandles,
        setLocalFileHandles,
        isStreaming,
        setIsStreaming,
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
}

export { ConversationProvider, ConversationContext };
