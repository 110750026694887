import { useTokens } from "./tokens";

const AUTH_API_HOST = `${window.env.REACT_APP_AUTH_API_HOST}`;

export function useCustomFetchNoAuth() {
  async function customFetchNoAuth(
    url,
    options = {},
    retries = 3,
    timeout = 5000
  ) {
    let authenticationError = false; // Flag to control the flow

    const fetchWithTimeout = (url, options) =>
      new Promise((resolve, reject) => {
        const timer = setTimeout(
          () => reject(new Error("Request timed out")),
          timeout
        );
        fetch(url, options)
          .then((response) => {
            clearTimeout(timer);
            if (response.status === 401) {
              authenticationError = true; // Set the flag to stop retries
              reject(
                new Error(
                  "Bad Request. Cannot refresh token, please login again."
                )
              );
            } else {
              resolve(response);
            }
          })
          .catch(reject);
      });

    for (let i = 0; i < retries && !authenticationError; i++) {
      // Check the flag in the loop condition
      try {
        const response = await fetchWithTimeout(url, options);
        if (!response.ok)
          throw new Error("Request failed with status: " + response.status);
        return response;
      } catch (error) {
        if (i === retries - 1 && !authenticationError) {
          throw error; // Throw the error if it's not related to login
        }
      }
    }

    // Redirect to login page if there is an authentication error and they are not already on the login page or home page
    if (authenticationError) {
      if (
        window.location.pathname !== "/login" &&
        window.location.pathname !== "/"
      ) {
        window.location.href = "/login";
      }
    }
  }

  return { customFetchNoAuth };
}

export function useCustomFetch() {
  const { checkAccessToken } = useTokens();

  async function customFetch(url, options = {}, retries = 3, timeout = 5000) {
    let authenticationError = false; // Flag to control the flow

    await checkAccessToken();

    const fetchWithTimeout = (url, options) =>
      new Promise((resolve, reject) => {
        const timer = setTimeout(
          () => reject(new Error("Request timed out")),
          timeout
        );
        fetch(url, options)
          .then((response) => {
            clearTimeout(timer);
            if (response.status === 401) {
              fetch(`${AUTH_API_HOST}/refresh`, {
                method: "POST",
                credentials: "include",
              })
                .then((refreshResponse) => {
                  if (refreshResponse.ok) {
                    // Get the access_token from the response and set it in local storage (for websocket connections)
                    // const data = await response.json();

                    refreshResponse.json().then((data) => {
                      localStorage.setItem("access_token", data.access_token);
                    });

                    fetch(url, options)
                      .then((retryResponse) => resolve(retryResponse))
                      .catch(reject);
                  } else {
                    authenticationError = true; // Set the flag to stop retries
                    reject(
                      new Error(
                        "Bad Request. Cannot refresh token, please login again."
                      )
                    );
                  }
                })
                .catch(reject);
            } else {
              resolve(response);
            }
          })
          .catch(reject);
      });

    for (let i = 0; i < retries && !authenticationError; i++) {
      // Check the flag in the loop condition
      try {
        const response = await fetchWithTimeout(url, options);
        if (!response.ok)
          throw new Error("Request failed with status: " + response.status);
        return response;
      } catch (error) {
        if (i === retries - 1 && !authenticationError) {
          throw error; // Throw the error if it's not related to login
        }
      }
    }

    // Redirect to login page if there is an authentication error and they are not already on the login page or home page
    if (authenticationError) {
      if (
        window.location.pathname !== "/login" &&
        window.location.pathname !== "/"
      ) {
        window.location.href = "/login";
      }
    }
  }

  return { customFetch };
}
